.errorMsg {
  color: #bf1650;
}

.errorMsg::before {
  display: inline;
  content: "⚠ ";
}

.image-holder {
  position: relative;
  justify-content: center;
  padding-right: 165px;
}

.image-holder .step-img-wrap {
  cursor: pointer;
}

.image-holder .step-img-wrap text {
  fill: #FF9800;
}

.image-holder .step-img-wrap>img {
  display: block;
  position: absolute;
}

.image-holder .ts-slice-icon {
  max-width: 50px
}

img.ts-slice-img {
  max-width: 188px;
}

.ts-section-title {
  position: absolute;
  color: #000;
  font-weight: 700;
  font-size: 18px;
}

.ts-slice-icon {
  max-width: 50px;
}

.step-img-wrap .step-active {
  border: 5px solid black
}

/*1 slice*/
.image-holder .step-img-wrap:nth-of-type(1)>.ts-slice-img {
  transform: rotate(270deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(1)>.ts-section-title {

  transform: rotate(270deg) translate(8em) rotate(90deg);

}

.image-holder .step-img-wrap.step-active:nth-of-type(1)>.ts-slice-img {
  transform: rotate(270deg) translate(8em) rotate(90deg);
}



.image-holder .step-img-wrap:nth-of-type(1)>.ts-slice-icon {
  transform: rotate(294deg) translate(9em) rotate(60deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(1)>.ts-slice-icon {
  transform: rotate(294deg) translate(10em) rotate(60deg);
}

/*2 slice*/

.image-holder .step-img-wrap:nth-of-type(2)>.ts-slice-img {
  transform: rotate(315deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(2)>.ts-slice-img {
  transform: rotate(315deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(2)>.ts-section-title {

  transform: rotate(340deg) translate(16em) rotate(70deg);

}

.image-holder .step-img-wrap:nth-of-type(2)>.ts-slice-icon {
  transform: rotate(342deg) translate(14em) rotate(20deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(2)>.ts-slice-icon {
  transform: rotate(342deg) translate(15em) rotate(20deg);
}

/*3 slice*/
.image-holder .step-img-wrap:nth-of-type(3)>.ts-slice-img {
  transform: rotate(0deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(3)>.ts-slice-img {
  transform: rotate(0deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(3)>.ts-section-title {

  transform: rotate(0deg) translate(8em) rotate(90deg);

}

.image-holder .step-img-wrap:nth-of-type(3)>.ts-slice-icon {
  transform: rotate(17deg) translate(18em) rotate(0deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(3)>.ts-slice-icon {
  transform: rotate(17deg) translate(19em) rotate(0deg);
}

/*4 slice*/

.image-holder .step-img-wrap:nth-of-type(4)>.ts-slice-img {
  transform: rotate(45deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(4)>.ts-slice-img {
  transform: rotate(45deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(4)>.ts-section-title {
  transform: rotate(56deg) translate(20.3em) rotate(81deg);
}

.image-holder .step-img-wrap:nth-of-type(4)>.ts-slice-icon {
  transform: rotate(48deg) translate(20em) rotate(-40deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(4)>.ts-slice-icon {
  transform: rotate(48deg) translate(21em) rotate(-40deg);
}

/*5 slice*/

.image-holder .step-img-wrap:nth-of-type(5)>.ts-slice-img {
  transform: rotate(90deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(5)>.ts-slice-img {
  transform: rotate(90deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(5)>.ts-section-title {
  transform: rotate(88deg) translate(22em) rotate(272deg);
}

.image-holder .step-img-wrap:nth-of-type(5)>.ts-slice-icon {
  transform: rotate(79deg) translate(19em) rotate(-80deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(5)>.ts-slice-icon {
  transform: rotate(79deg) translate(20em) rotate(-80deg);
}

/*6 slice*/

.image-holder .step-img-wrap:nth-of-type(6)>.ts-slice-img {
  transform: rotate(135deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(6)>.ts-slice-img {
  transform: rotate(135deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(6)>.ts-section-title {
  transform: rotate(125deg) translate(21.3em) rotate(104deg);
}

.image-holder .step-img-wrap:nth-of-type(6)>.ts-slice-icon {
  transform: rotate(114deg) translate(16em) rotate(240deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(6)>.ts-slice-icon {
  transform: rotate(114deg) translate(17em) rotate(240deg);
}

/*7 slice*/
.image-holder .step-img-wrap:nth-of-type(7)>.ts-slice-img {
  transform: rotate(180deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(7)>.ts-slice-img {
  transform: rotate(180deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(7)>.ts-section-title {
  transform: rotate(159deg) translate(17em) rotate(109deg);
}

.image-holder .step-img-wrap:nth-of-type(7)>.ts-slice-icon {
  transform: rotate(153deg) translate(12em) rotate(210deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(7)>.ts-slice-icon {
  transform: rotate(153deg) translate(13em) rotate(210deg);
}

/*8 slice*/
.image-holder .step-img-wrap:nth-of-type(8)>.ts-slice-img {
  transform: rotate(225deg) translate(7em) rotate(90deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(8)>.ts-slice-img {
  transform: rotate(225deg) translate(8em) rotate(90deg);
}

.image-holder .step-img-wrap:nth-of-type(8)>.ts-section-title {
  transform: rotate(212deg) translate(11.3em) rotate(102deg);
}

.image-holder .step-img-wrap:nth-of-type(8)>.ts-slice-icon {
  transform: rotate(216deg) translate(8em) rotate(140deg);
}

.image-holder .step-img-wrap.step-active:nth-of-type(8)>.ts-slice-icon {
  transform: rotate(216deg) translate(9em) rotate(140deg);
}

@media only screen and (max-width: 600px) {
  .image-holder {
    transform: scale(0.55);
    overflow: inherit;
    padding-right: 165px;

  }

  .user-email-box {
    /* padding-top: 330px  !important;
    font-size: 18px !important;
     */

    /* margin-top: 20px !important; */
    border-radius: 0 !important;

  }

  .MuiAutocomplete-listbox {
    font-size: 14px !important;
    padding: 1px 1px 1px 1px !important;
  }

  .MuiAutocomplete-listbox {
    font-size: 14px !important;
    padding: 1px 1px 1px 1px !important;
  }

  .referrals_notice {
    font-size: 12px !important;

  }

  .form-button {
    padding: 8px 16px !important;
  }

}
/* @media only screen and (max-width: 1353px) and (min-width:1200px) {
  .form-button {
    margin-left: 15vw !important;
  }

} */
@media only screen and (max-width: 1600px) and (min-width:1453px) {
  .form-button {
    /* margin-left: 21vw !important; */
  }

}

@media only screen and (max-width: 900px) and (min-width:600px) {
  .image-holder {
    transform: scale(0.9);
    overflow: inherit;
    padding-right: 165px;
  }

}

@media only screen and (max-width: 1920px) and (min-width:1600px) {
  .image-holder {
    transform: scale(1.2);
    overflow: inherit;
    padding-right: 165px;
  }
}

/* @media only screen and (max-width: 1439px) and (min-width:1265px) {
   #proceed-button{
    margin-left: 22vw !important;
  }
} */
/* @media only screen and (max-width: 1280px)  {
  .stepper-main-grid{
    margin-top: 74px !important ;
  }
} */
@media only screen and (max-width: 1600px) and (min-width:1300px) {
  .image-holder {
    transform: scale(0.9);
    overflow: inherit;
    /* padding-right: 100px; */
    /* padding-bottom: 0px !important; */
    /* padding-bottom: 300px !important; */
    /* padding-top: 100px !important; */
  }
  
  #back-button{
    /* margin-left: 3vw !important; */
  }
  .stepper-form{
    padding-bottom: 22px !important;
  }

  .pizza-holder-main {
    /* padding-top: 300px !important; */
  }

  .user-email-box {
    padding-top: 430px !important;
    font-size: 18px !important;
    /* margin-top: 20px !important; */
  }
}

@media only screen and (max-width: 1300px) and (min-width:1200px) {
  .image-holder {
    transform: scale(0.8);
    overflow: inherit;
    /* padding-right: 100px; */
    /* padding-bottom: 0px !important; */
    /* padding-bottom: 300px !important; */
    /* padding-top: 100px !important; */
  }

  .pizza-holder-main {
    /* padding-top: 200px !important; */
  }

  .user-email-box {
    padding-top: 350px !important;
    font-size: 18px !important;
    /* margin-top: 20px !important; */
  }
}

@media only screen and (max-width: 1200px) and (min-width:900px) {
  .image-holder {
    transform: scale(0.8);
    overflow: inherit;
    /* padding-right: 100px; */
    /* padding-bottom: 0px !important; */
    /* padding-bottom: 300px !important; */
    /* padding-top: 100px !important; */
    padding-left: 100px !important;
  }

  .pizza-holder-main {
    /* padding-top: 200px !important; */


  }

  .user-email-box {
    /* padding-top: 320px !important; */
    font-size: 18px !important;
    /* margin-top: 20px !important; */
  }
}

.referrals_notice {
  font-size: 16px;
  color: red
}

.animate-option {
  transform: rotateY(360deg);
  transition: all linear 0.4s;
}

/* @media only screen and (max-width: 1280px) and (min-width:1366px) {
  .image-holder {
      transform: scale(0.6);
      overflow: inherit;
      
  }
} */