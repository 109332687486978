/* src/ShinyTypography.css */

@keyframes shine {
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  }
  
  .shiny-text {
    background: linear-gradient(90deg, #ffff, #ffff, #FFC218);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 12s linear infinite;
    display: inline-block;
    
  }
  