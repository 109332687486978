body {
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    overflow-x: hidden;    
    overflow-y: scroll !important;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 1200px) {
    body{
        overflow-y: scroll !important;
    }
  }

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
    font-weight: normal;
}

p {
    margin: 0px;
    padding: 0px;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

a:hover,
a:focus,
a:active {
    outline: none;
    text-decoration: none;
}

input:hover,
input:focus,
input:active {
    outline: none;
}

ul {
    margin: 0px;
    padding: 0px;
}

ul li {
    list-style-type: none;
}

img {
    border: 0px;
    vertical-align: middle;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.clearfix {
    clear: both;
    overflow: hidden;
}

.wrapper {
    margin: 0px auto;
}

.container {
    max-width: 1650px;
    margin: auto;
}

@-webkit-keyframes animate-block-gorizantal-2 {
    50% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes animate-block-gorizantal-2 {
    50% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.common_btn_hp {
    background: #044AFF;
    border-radius: 10px;
    padding: 12px 0 12px 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
    min-width: 264px;
    display: inline-block;
    position: relative;
}

.common_btn_hp:hover {
    background: #000;
    color: #fff;
    opacity: 4;
}

/*--------------- HEADER_START --------------*/
#header {
    padding: 0px;
    margin: 0px;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 9999;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header-in-hp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 0px 80px 0 42px;
}

.logo-hp {
    float: left;
    padding: 0px 0px 0 0px;
    width: 191px;
    align-self: center;
}

.logo-hp img {
    width: 100%;
    vertical-align: middle;
}

.navigation {
    width: calc(100% - 191px);
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.dropdown-submenu {
    position: relative;
    padding: 5px 0;
}

.dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: .8em;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: .1rem;
    margin-right: .1rem;
}

.navbar {
    padding: 0px;
    position: static;
    align-items: center;
}

.navbar-nav .nav-item {
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    display: inline-block;
    margin: 0 0px;
    vertical-align: middle;
    position: relative;
}

.navbar-nav .nav-link {
    color: #000 !important;
    padding: 9px 20px 9px 20px !important;
    display: block;
    position: relative;
}

.navbar-nav .nav-link.login_hp {
    background: #044AFF;
    border-radius: 5px;
    color: #fff !important;
    padding: 9px 25px !important;
    font-size: 19px;
    line-height: 22px;
    margin-left: 20px;
}

.navbar-nav .nav-link.login_hp:hover {
    background: #000;
    color: #fff !important;
}

/*.navbar-nav .nav-link:after{ display:none; }*/
/*.navbar-nav .nav-link:before{ content:''; position:absolute; background:#000000; height:2px; width:0%; left:50%; bottom:36px; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -o-transition: all 0.3s linear; -ms-transition: all 0.3s linear; transition: all 0.3s linear; }*/
.navbar-nav .nav-link:hover {
    color: #F26531 !important;
}

/*.navbar-nav .nav-link:hover:before{ left:20px; width:calc(100% - 40px); }*/
.navbar-nav .show .nav-link {
    color: #F26531 !important;
}

/*.navbar-nav .show .nav-link:before{ left:20px; width:calc(100% - 40px); }*/
.navbar-nav .nav-link.active {
    color: #F26531 !important;
}

/*.navbar-nav .nav-link.active:before{ left:20px; width:calc(100% - 40px); }*/

.navbar-nav .nav-link.contact_hp:before {
    display: none;
}

.navbar-nav .nav-item.dropdown {}

.navbar-nav .nav-item.dropdown .nav-link {}

.navbar-nav .dropdown-menu {
    border: 0px;
    border-radius: 0px;
    margin: 0px;
    padding: 0;
    top: 76px;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
    width: 100%;
    left: 20px;
    display: block;
    opacity: 0;
    z-index: -1;
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
    transform: translate(0px, -10px);
}

.navbar-nav .dropdown-menu ul {
    padding: 15px 0;
}

.navbar-nav .dropdown-menu ul li {}

.navbar-nav .dropdown-menu ul li a {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #2C2C2C;
    padding: 8px 10px 8px 20px;
    display: block;
}

.navbar-nav .dropdown-menu ul li a:hover {
    background: none;
    color: #2C2C2C;
}

.hide-desktop-hp {
    display: none !important;
}

.mobile-menu-icon-hp {
    margin: 0px;
    padding: 0px;
    float: right;
    display: none;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.menu-toggle-btn {
    margin: 20px 0px 20px 0px;
    cursor: pointer;
    width: 25px;
    height: 22px;
    padding: 0;
    position: relative;
    z-index: 999999999;
}

.menu-toggle-btn span {
    background: #000;
    display: block;
    width: 25px;
    height: 2px;
    border-radius: 0px;
    margin-bottom: 8px;
    -webkit-transition: all 0.5s linear;
    transition: all 0.3s linear;
}

.menu-toggle-btn span:last-child {
    margin-bottom: 0px;
}

.menu-toggle-btn span:nth-child(1) {
    width: 100%;
}

.menu-toggle-btn span:nth-child(2) {
    width: 100%;
}

.menu-toggle-btn span:nth-child(3) {
    width: 100%;
}

.menu-toggle-btn.open span:nth-child(1),
.menu-toggle-btn.open span:nth-child(3) {
    transform: translate(0px, 12px) rotate(-45deg) scalex(1.3);
    margin: 0;
    width: 100%;
}

.menu-toggle-btn.open span:nth-child(2) {
    height: 0;
    margin: 0;
}

.menu-toggle-btn.open span:nth-child(3) {
    transform: translate(0px, 10px) rotate(45deg) scalex(1.3);
}

.overlay-mobile-menu-hp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 999;
    opacity: 0;
    z-index: -11;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.menu-text-hp {
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    line-height: 7px;
    margin: 0 0 0 -5px;
    position: absolute;
    top: 25px;
}

/*.show_menu .overlay-mobile-menu-hp{ background:rgba(29,56,53,0.9); opacity:1; z-index:991; }
.show_menu #header{ background:#fff; }*/
.header-absolute-hp {
    position: absolute;
    left: 53px;
    top: 50%;
    z-index: 3;
}

.fix-header #header {
    position: fixed;
    background: #fff;
}

.fix-header #header.header_back {
    position: fixed;
    background: #fff;
}

.fix-header .header-in-hp {
    height: 90px;
}

/*--------------- HEADER_END --------------*/

/*--------------- CONTAIN_START --------------*/
#contain {
    margin: 0px;
    padding: 0px 0 0 0;
    overflow: hidden;
}

.banner_block_hp {
    padding: 132px 0 52px 0;
}

.banner_middle_hp {
    display: flex;
    align-items: end;
}

.banner_left_hp {
    width: calc(100% - 885px);
    padding: 0 35px 80px 0;
}

.banner_left_title_hp {}

.banner_left_title_hp h2 {
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #000000;
}

.banner_left_title_hp p {
    padding-top: 19px;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #404040;
}

.banner_left_btn_hp {
    padding-top: 30px;
}

.banner_img_hp {
    width: 885px;
}

.banner_img_hp img {
    width: 100%;
}

.sevedo_block_hp {
    background: #F9F9F9;
    padding: 52px 0 58px 0;
}

.sevedo_middle_hp {}

.sevedo_title_hp {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: #000000;
}

.sevedo_btn_row_hp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 58px;
}

.sevedo_btn_hp {
    padding-right: 82px;
}

.sevedo_btn_hp:last-child {
    padding-right: 0;
}

.sevedo_btn_hp a {
    min-width: 440px;
}

/*--------------- CONTAIN_END --------------*/

/*--------------- FOOTER_START --------------*/
#footer {
    margin: 0;
    padding: 0;
}

.footer_in_hp {}

/*--------------- FOOTER_END --------------*/


/*--------------- LOGIN_START ------------*/
.login-page-pizza-img{
    max-width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
}
.login-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*--------------- LOGIN_END ------------*/

/*--------------- MUI_TOOLTIP START ------------*/
.question-heading{
    display: "flex" ;
    align-items: "center" ;
}
/*--------------- MUI_TOOLTIP END ------------*/

/* border left */

.custom-border{
    width: 7px;
    height: 100%;
    background: #2BB344;    
    display: inline-block;
    margin-right: 10px;

}


::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a8a8a8; 
    border-radius:5px

  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8; 
  }

