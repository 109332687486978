.steps-pizza {
    position: relative;
    display: block;
}

.steps-pizza>img {
    display: block;
    position: absolute;
}

.steps-pizza>img:nth-of-type(1) {
    transform: rotate(0deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(2) {
    transform: rotate(44deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(3) {
    transform: rotate(90deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(4) {
    transform: rotate(135deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(5) {
    transform: rotate(180deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(6) {
    transform: rotate(225deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(7) {
    transform: rotate(270deg) translate(9em) rotate(90deg);
}

.steps-pizza>img:nth-of-type(8) {
    transform: rotate(315deg) translate(9em) rotate(90deg);
}

.avatar {
    position: absolute;
    z-index: 1;

}

.step-img-wrap {
    all: unset;
}

img.ts-slice-img {
    width: 188px;
}

.avatar-img {
    width: 70px !important;
    height: 70px !important;

}

.avatar-label {
    background-color: #B6FFC3;
    padding: 0px 18px 0px 30px;
    border-radius: 25px;
    position: relative;
    left: 30px;
}


.stepper-question {
    font-size: 17px !important;
    font-weight: 500 !important;
    padding-bottom: 6px !important;
    /* line-height: 32px !important; */
}

@media only screen and (max-width: 600px) {
    .image-holder {
        /* padding-bottom: 300px !important; */
        /* padding-top: 100px !important; */
    }

    .avatar-label {
        font-size: 20px !important;
    }

    .user-email-box {
        font-size: 6px !important;
    }
    .stepper-question {
        font-size: 15px !important;
        font-weight: 500 !important;
        padding-bottom: 6px;
        /* line-height: 32px !important; */
    }
}

@media only screen and (max-width: 900px) and (min-width:600px) {
    .image-holder {
        /* padding-bottom: 500px !important; */
        /* padding-top: 200px !important; */
    }

    .avatar-label {
        font-size: 20px !important;
    }
}