.center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.self-img {
  position: relative;
  left: 120px;
}

/* .avatar-lebel{
    width: 80px;
} */
.techvalue {
  position: absolute;
  z-index: 1;

}

.techvalue-img {
  width: 50px !important;
  height: 50px !important;
}

.techvalue-label-new {
  background-color: rgba(241, 241, 241, 1);
  padding: 25px;
  border-radius: 50px;
  position: relative;
  left: 15px;
  /* width: 200px; */
  /* padding: 10px 30px 10px 60px;
    border-radius: 100px;
    position: relative;
    left: 0px;
    bottom: 0px; */

}

.value-label-new {
  background-color: rgba(241, 241, 241, 1);
  padding: 15px 40px 15px 40px;
  border-radius: 100px;
  position: relative;
  /* left: 25px; */
  text-align: center;
  min-width: 200px;
  /* width: 100px; */

  /* padding: 10px 30px 10px 30px;
     border-radius: 100px;
     position: relative;
     left: 25px; */

}

.box_ab {
  position: absolute;
  top: 89px;
  right: 167px;
  z-index: -1;
}

@media screen and (max-width:1199px) {
  .techvalue-label-new {
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    position: relative;
    left: 5px;
    bottom: 0px;
    font-size: 20px !important;
    /* width: 180px; */
  }

  .label-img {
    width: 76px !important;
    height: 76px !important;
  }

  .value-label-new {
    padding: 10px 10px 10px 10px !important;
    border-radius: 100px;
    position: relative;
    left: 0px !important;
    font-size: 20px !important;
    width: 150px !important;
    min-width: 150px !important;

  }

  .techworth-label {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 600px) {
  .techvalue-label-new {
    padding: 8px 8px 8px 8px;
    border-radius: 50px;
    position: relative;
    left: 5px;
    bottom: 0px;
    font-size: 14px !important;
    /* width: 120px; */
  }

  .speedometer {
    /* height: 100px !important;
        width: 200px !important;
         */
  }

  .evaluting {
    width: 259px !important;
  }
}

@media only screen and (max-width: 600px) {
  .label-img {
    width: 50px !important;
    height: 50px !important;
  }

  .ellipse-red {
    max-width: 98px !important;
    top: 205px !important;
    left: 50px !important;
    z-index: -1 !important;
    position: absolute !important;
  }

  .ellips-blue {
    max-width: 98px !important;
    top: 250px !important;
    left: 230px !important;

    z-index: -1 !important;
    position: absolute !important;
  }

  .self-attested-logo {
    width: 60px
  }
}

@media only screen and (max-width: 600px) {
  .value-label-new {
    padding: 10px 10px 10px 10px;
    border-radius: 100px;
    position: relative;
    left: 0px !important;
    font-size: 14px !important;
    width: 120px !important;
    min-width: 80px !important;
  }

  .techworth-label {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 600px) {
  .box_ab {
    position: absolute;
    z-index: -1;
    right: 0px;
    top: -20px !important;
  }

  .techworth-logo-new {
    width: 75px !important;
    height: 75px !important;
    z-index: 1;
  }
}

@media only screen and (max-width: 600px) {
  br {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .answers {
    padding: 0px 18px 5px 18px;
    font-size: 12px !important;
  }

  .typing-textarea {
    font-size: 14px !important;
    width: 260px !important;
    font-family: 'Poppins', sans-serif;
    min-height: 500px !important;
    line-height: 20px !important;
    height: fit-content !important;
    word-wrap: break-word !important;
    padding: 5px !important;
  }

  .MuiDialogContent-root {
    padding: "0px 15px !important";
  }

  .MuiPaper-root-MuiDialog-paper {
    margin: "0px 10px !important";
  }
}

@media only screen and (max-width: 600px) {
  .btn {
    font-size: 14px !important;
  }
}

@media only screen and (min-width:899px)and (max-width:600px) {
  .techworth-formula {
    padding-left: 100px !important;
  }

  .techworth-logo-new {
    margin-top: 20px;
  }
}


/* bio letter animation */
/* .typing-animation {
  display: inline-block;
  font-size: 18px;
  padding: 8px;
}

.text {
  overflow: hidden;  
  animation: typing 1s steps(5, end) infinite, blink-caret 0.75s steps(1) infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-right: 2px solid transparent;
  }
  50% {
    border-right: 2px solid #000;
  }
} */

.typing-animation {
  display: flex;
  align-items: center;
  justify-content: center;

}

.typing-textarea {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  width: 500px;
  min-height: 500px;
  height: fit-content;
  line-height: 25px;
  padding: 15px;
  word-break: break-all;
  position: relative;
  /* Position for the span element */
  /* Prevent text from wrapping to the next line */
  /* animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite; */


  @keyframes typing {
    from {
      width: 0;
    }

    to {
      width: 300px;
    }
  }

  @keyframes blink-caret {

    from,
    to {
      border-color: transparent;
    }

    50% {
      border-color: #333;
      /* Blinking cursor color */
    }
  }
}


/* tech result v3 css */

/* .speedometer-div .speedometer {
  width: 350px !important;
  height: 210px !important;
}

@media screen and (max-width:1199px) {
  .result-speedometer {
    width: 350;
    height: 210;
  }
}

@media screen and (max-width:1400px) {
  .speedometer-div .speedometer {
    width: 250px !important;
    height: 160px !important;
  }
} */

/* tech result v3 css  over*/