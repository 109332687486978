@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.chat-box {
  width: 300px;
  margin: 50px auto;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.chat-message {
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
}

/* Typing animation */
@keyframes typing {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.chat-message::after {
  content: '|';
  display: inline-block;
  animation: typing 1s step-end infinite;
}

/* testimonial css old */

.testimonial-swiper .swiper-slide {
  height: auto;
}

.testimonial-swiper .swiper-button-prev,
.testimonial-swiper .swiper-button-next {
  width: 79px;
  height: 79px;
  background: #2BB344;
  color: #FFF;
  border-radius: 50%;
}

.testimonial-swiper .swiper-button-prev::after,
.testimonial-swiper .swiper-button-next::after {
  font-size: 22px;
  color: #FFF;

}

.testimonial-swiper .swiper-button-disabled {
  background: #D9D9D9;
  opacity: 1;
}

.testimonial-swiper .swiper-button-disabled::after {
  color: #636363
}

.testimonial-swiper .swiper-button-prev {
  top: 32%;
}

.testimonial-swiper .swiper-button-next {
  top: 52%;
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
@media screen and (max-width: 1200px) {
  #home-video{
    /* height: 80vh !important; */
  }
  .Software_Personnel_Title{
    /* padding-top: 50px !important; */
  }
}


@media screen and (max-width:1187px) {

  .testimonial-swiper {
    margin-bottom: 90px;
    /* overflow: revert; */
    overflow-y: unset;
  }

  .testimonial-swiper .swiper-button-prev,
  .testimonial-swiper .swiper-button-next {
    width: 50px;
    height: 50px;
  }

  .testimonial-swiper .swiper-button-prev {
    top: unset;
    bottom: -15%;
    left: 39%
  }

  .testimonial-swiper .swiper-button-next {
    top: unset;
    bottom: -15%;
    left: 51%
  }
}


@media screen and (max-width:438px) {
  .testimonial-swiper .swiper-button-prev {
    left: 33%
  }

  .testimonial-swiper .swiper-button-next {
    left: 57%
  }
}

/* testimonial css old over */

/* references table media query, */

@media screen and (max-width: 1600px) {
  .references-table{
    max-width: 600px !important;
  }
}




/* testimonial css new */

.home-swiper {
  /* padding-right: 60px; */
  /* padding-left:30px; */
  /* overflow: hidden; */
  padding-top: 3px;
  padding-bottom: 30px;
  margin-top: 3px;
  margin-bottom: 28px;
  overflow-y: visible;
  max-width: 1649px;
  /* margin-left: 10vw;
  margin-right: 10vw; */

}

.overlay {
  position: absolute;
  top: 7.5%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(35, 28, 79, 0.3);  */
}

.home-swiper .swiper-slide {
  height: auto;
}

.home-swiper .swiper-button-prev,
.home-swiper .swiper-button-next {
  width: 32px;
  height: 32px;
  background: #2BB344;
  color: #FFF;
  border-radius: 50%;
}

.home-swiper .swiper-button-prev::after,
.home-swiper .swiper-button-next::after {
  font-size: 16px;
  color: #FFF;

}

.home-swiper .swiper-button-disabled {
  background: #D9D9D9;
  opacity: 1;
}

.home-swiper .swiper-button-disabled::after {
  color: #636363
}

.home-swiper .swiper-button-prev {
  left: 46%;
  top:100%;
}

.home-swiper .swiper-button-next {
  right: 46%;
  top:100%;
}

.home-swiper .swiper-slide-active {
  /* margin-left: 2%; */
}

.home-swiper .swiper-slide-next {
  width: 365px
}


@media screen and (max-width:1187px) {

  .home-swiper {
    /* margin-bottom: 90px; */
    overflow-y: unset;
  }

  .home-swiper .swiper-slide-active {
    margin-left: unset;
  }

  .home-swiper .swiper-slide-next {
    width: unset
  }
  .home-swiper .swiper-button-prev {
    left: 40%;
    top:100%;
  }
  
  .home-swiper .swiper-button-next {
    right:40%;
    top:100%;
  }


}
@media screen and (max-width: 1600px) {
  .stepper-form-main{
    min-height: 90vh !important;
  }
}
@media screen and (max-width: 1440px) {
  .stepper-form-main{
    min-height: 92.6vh !important;
  }
}
@media screen and (max-width: 1265px) {
  .stepper-form-main{
    min-height: 90.9vh !important;
  }
}

@media screen and (max-width:1024px) {

  .home-swiper {
    padding-right: 38px;
    padding-left:38px;
  }
}

@media screen and (max-width:438px) {
  .home-swiper {
    /* padding-right: unset; */
  }
  .home-swiper{
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
  }

  .home-swiper .swiper-button-prev {
    top: 80% !important;
    left: 5% !important;    
  }

  .home-swiper .swiper-button-next {
    top: 80% !important;
    right:5% !important
  }
}

/* testimonial css new over */


.clamp-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
#myVideo {
  max-width: 1920px;
  /* position: fixed; */
  /* right: 0;
  bottom: 0; */
  /* min-width: 50%;  */
  /* min-height: 50%; */
}

.gradient-border-mask {
  display: flow-root;
  position: relative;
  padding: 1.3rem;
}

.gradient-border-mask::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 26px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #2BB344, #FFC218, #E0011C, #0BA2FF) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none; /* Ensure clicks pass through */
}

.stepper-question{
  padding-bottom: "4px";
}
