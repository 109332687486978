.signin-pizza{
    transform: scale(1.2);
        overflow: inherit;
  }
.signin-img-keyboard{
    transform: scale(0.9);
    overflow: inherit;   
}
@media screen and (max-width:600px) {
    .sign-box{
        min-width: 300px !important;
    }
}
@media screen and (max-width:1400px) and (min-width:1920px){
    .sign-box{
        max-width: 373px !important;
    }

}